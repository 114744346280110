import * as Yup from "yup";
import { EditableEngage, EditableGroup } from "../types/EditableGroup";

export const editableCepSchema = Yup.object<EditableEngage>().shape({
    id: Yup.string().nullable(true),
    active: Yup.boolean().nullable(false).required("Required"),
});

export const editableGroupSchema = Yup.object<EditableGroup>().shape({
    id: Yup.string().nullable(false).required("Required"),
    displayName: Yup.string().required("Required"),
    privacyPolicyUrl: Yup.string().url("Must be a valid URL beginning with http or https").nullable(true),
    engage: editableCepSchema,
});
