import { NavNode, NavNodeTextOptions } from "../types/NavMenu";
import { config } from "common/config";
import { posOperationBehaviorFilter, posOperationFilter } from "features/posConfiguration/selectors/posOperationFilter";
import { Home, Menu, Wallet } from "common/icons";
import { Marketing } from "common/icons/Marketing";
import { OpenInWindow } from "common/icons/OpenInWindow";
import { showMembershipsFilter } from "features/memberships/selectors/showMembershipsFilter";
import { showPriceListsFilter } from "features/priceList/selectors/showPriceListsFilter";
import { findNavItemsRecursive, isChildEnabledNavItem, isParentEnabledNavItem } from "common/data/navUtil";
import { showYumpingoOptionsFilter } from "../../yumpingo/selectors";
import { showSettingsFilter } from "features/location/selectors/showSettingsFilter";

const getLocationName = (isParentLocation: boolean) => {
    return isParentLocation ? "Brand" : "Venue";
};

const getMyVenueGroupName = ({ isParentLocation }: NavNodeTextOptions) => {
    return `My ${getLocationName(isParentLocation)}`;
};

const getMyDetailsPageName = ({ isParentLocation }: NavNodeTextOptions) => {
    return `${getLocationName(isParentLocation)} Details`;
};

const navStructure: NavNode[] = [
    {
        text: "Launch Venue Manager",
        icon: OpenInWindow,
        permission: "deviceenrolment:create",
        externalUrl: `${config.VITE_VENUE_APP_LINK!}/:region/enrol/:location`,
        category: "prominent",
        testId: "launch-venue-manager",
    },
    {
        text: "Management",
        testId: "management",
        navItemGroups: [
            {
                text: getMyVenueGroupName,
                icon: Home,
                testId: "myvenue",
                children: [
                    {
                        text: "me&u 2.0",
                        permission: "location:read",
                        route: "meandu2",
                        testId: "migration-link",
                    },
                    {
                        text: getMyDetailsPageName,
                        permission: "location:update",
                        route: "edit",
                        includeForParentLocation: true,
                        testId: "venue-details",
                    },
                    {
                        text: "Linked Venues",
                        permission: "settings:read",
                        route: "venues",
                        includeForParentLocationOnly: true,
                        testId: "linkedvenues",
                    },
                    {
                        text: "Branding",
                        permission: "location:update",
                        route: "branding",
                        testId: "branding",
                    },
                    {
                        text: "Sections",
                        permission: "section:read",
                        route: "sections",
                        testId: "sections",
                    },
                    {
                        text: "Staff",
                        permission: "staff:read",
                        route: "staff",
                        testId: "staff",
                    },
                    {
                        text: "Settings",
                        permission: "settings:read",
                        route: "settings",
                        testId: "settings",
                        includeForParentLocation: true,
                        displayFilter: showSettingsFilter,
                    },
                    {
                        text: "POS Setup",
                        permission: "location:pos:configuration:read",
                        route: "pos/configuration",
                        displayFilter: posOperationFilter("SetConfiguration"),
                        testId: "possetup",
                        includeForParentLocation: true,
                    },
                    {
                        text: "Takeaway",
                        permission: "settings:read",
                        route: "takeaway",
                        includeForCurrencies: ["NZD", "AUD"],
                        displayFilter: posOperationBehaviorFilter("OrderCreate", "Takeaway"),
                        testId: "takeaway",
                    },
                    {
                        text: "Migration",
                        permission: "location:migration:create",
                        route: "migration",
                        testId: "migration",
                    },
                ],
            },
            {
                text: "Menu",
                icon: Menu,
                testId: "menu",
                children: [
                    {
                        text: "Catalogue",
                        permission: "catalogue:read",
                        route: "menu/catalogue",
                        includeForParentLocation: true,
                        testId: "catalogue",
                    },
                    {
                        text: "Categories",
                        permission: "menudata:read",
                        route: "menu/categories",
                        testId: "categories",
                        includeForParentLocation: true,
                    },
                    {
                        text: "Services",
                        permission: "menudata:read",
                        route: "menu/services",
                        testId: "services",
                    },
                    {
                        text: "Surcharges",
                        permission: "surcharges:read",
                        route: "menu/surcharges",
                        featureToggle: "enableSurchargesFeature",
                        testId: "surcharges",
                    },
                    {
                        text: "Taxes & Fees",
                        permission: "taxes:read",
                        route: "menu/taxes",
                        includeForCurrencies: ["USD"],
                        testId: "taxes",
                    },
                    {
                        text: "Price Lists",
                        permission: "pricelist:update",
                        route: "menu/pricelists",
                        displayFilter: showPriceListsFilter(),
                        testId: "pricelists",
                    },
                    {
                        text: "Quick Sell",
                        permission: "quicksell:read",
                        route: "quicksell",
                        testId: "quicksell",
                    },
                    {
                        text: "Group Tabs",
                        permission: "grouptabs:management:read",
                        route: "menu/grouptabs",
                        testId: "grouptabs",
                    },
                ],
            },
            {
                text: "Marketing",
                icon: Marketing,
                testId: "marketing",
                children: [
                    {
                        text: "Promotions",
                        permission: "promotions:read",
                        route: "promotions",
                        featureToggle: "enablePromotions",
                        displayFilter: posOperationBehaviorFilter("OrderCreate", "Discounts"),
                        testId: "promotions",
                    },
                    {
                        text: "Loyalty",
                        permission: "membership:read",
                        route: "membership",
                        configEnabled: config.VITE_ENABLE_MEMBERSHIP === "1",
                        displayFilter: showMembershipsFilter(),
                        testId: "membership",
                    },
                    {
                        text: "Yumpingo",
                        permission: "settings:update",
                        route: "yumpingo",
                        displayFilter: showYumpingoOptionsFilter(),
                        testId: "yumpingo",
                    },
                ],
            },
            {
                text: "Payments",
                icon: Wallet,
                testId: "payments",
                children: [
                    {
                        text: "Sales Summary",
                        permission: "reports:sales",
                        route: "reports/dailysales",
                        testId: "salessummary",
                    },
                    {
                        text: "Hyperwallet",
                        permission: "payment:read",
                        route: "payments",
                        testId: "hyperwallet",
                    },
                    {
                        text: "Stripe",
                        permission: "stripe:read",
                        route: "stripe",
                        featureToggle: "enableStripeOnboarding",
                        testId: "stripe",
                    },
                ],
            },
        ],
    },
    {
        text: "Support",
        icon: Home,
        permission: "menudata:read",
        externalUrl: `https://meandu.helpjuice.com/?utm_source=cms&utm_medium=internal&utm_campaign=:locationId`,
        testId: "support",
    },
];

export const getNavStructure = () => navStructure;

export const parentEnabledNavItems = findNavItemsRecursive(navStructure, isParentEnabledNavItem);

export const childEnabledNavItems = findNavItemsRecursive(navStructure, isChildEnabledNavItem);
