import { opsApi } from "API";
import { ActiveGroup } from "../types/ActiveGroup";
import { analyticsLayoutConfigQueryFields } from "features/analytics/api/analyticsLayoutConfigQueryFields";

export const fetchGroup = async (slug: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { groupId: slug });
    return res.data.group;
};

type GraphQLPayload = { group: ActiveGroup };

export const activeGroupFields = `
    displayName,
    id,
    integrations {
        id, 
        provider, 
        active, 
        category
    },
    locationsWithEngage {
        id,
        displayName,
        isActive,
        parentId,
        childIds
    },
    slug,
    privacyPolicyUrl,
    authInfo {
        permissions,
        userRoles
    }
    analyticsLayoutConfig {
        ${analyticsLayoutConfigQueryFields}
    },
    userHasTeams
`;

const query = `
    query ($groupId: ID!) {
        group(groupId: $groupId) {
            ${activeGroupFields}
        }
    }
`;
