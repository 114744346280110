import Hotjar from "@hotjar/browser";
import { config } from "common/config";
import { AppDispatch } from "features";
import { auth } from "common/auth";
import { FetchActiveSuccessAction, TypeKeys } from "features/location/reducers/active";
import { ActiveLocation } from "features/location/types/ActiveLocation";
import { trackException } from "common/appInsights";
import { isInternalUser } from "features/userManagement/utils";

const initHotjarPlugin = () => {
    const siteId = config.VITE_HOTJAR_SITE_ID;
    const hotjarVersion = 6;

    const siteIdValue = Number.parseInt(siteId);
    if (!siteId || isNaN(siteIdValue)) return;

    Hotjar.init(siteIdValue, hotjarVersion);
};

const setHotjarIdentity = (location: ActiveLocation) => {
    const authState = auth.getState();
    if (Hotjar.isReady() && authState !== null) {
        const { id } = authState;
        const firstRole = location.authInfo.userRoles?.[0] || "Unknown";
        const groupName = location.group?.displayName || "Unknown";

        // Can't track user by email because of privacy concerns
        let idHash = 0;
        for (let i = 0; i < id.length; i++) {
            idHash = (idHash << 5) - idHash + id.charCodeAt(i);
            idHash |= 0; // Convert to 32bit integer
        }

        Hotjar.identify(Math.abs(idHash).toString(), {
            locationId: location.id,
            locationName: location.displayName,
            groupName: groupName,
            internalUser: isInternalUser(location),
            role: firstRole,
            platform: "cms",
        });
    }
};

export const hotjarMiddleware = () => () => (next: AppDispatch) => {
    return (action: FetchActiveSuccessAction) => {
        if (action.type === TypeKeys.SUCCESS && auth.isAuthenticated() && !isInternalUser(action.data)) {
            try {
                if (!Hotjar.isReady()) {
                    initHotjarPlugin();
                }

                setHotjarIdentity(action.data);
            } catch (e) {
                trackException(e);
            }
        }
        return next(action);
    };
};
