import { opsApi } from "API";
import { activeGroupFields } from "./fetchGroup";
import { Group } from "../types/Group";

export const saveGroup = async (groupId: string, groupSettingsUpdate: GroupSettingsUpdateInput) => {
    const res = await opsApi.graphQLQuery<{ updateGroupSettings: Group }>(updateQuery, {
        groupId,
        groupSettingsUpdate,
    });
    return res.data.updateGroupSettings;
};

export interface GroupSettingsUpdateInput {
    displayName: string;
    privacyPolicyUrl?: string;
    integrationsSettings?: IntegrationsSettings;
}

export interface IntegrationsSettings {
    cepSettings?: CepSettings[];
}

export interface CepSettings {
    active: boolean;
    id?: string;
    locationsEnabled?: EditableEngageLocationsEnabled[];
}

export interface EditableEngageLocationsEnabled {
    locationId: string;
    isActive: boolean;
}

const updateQuery = `
    mutation($groupId: ID!, $groupSettingsUpdate: GroupSettingsUpdateInput!) {
        updateGroupSettings(groupId: $groupId, groupSettings: $groupSettingsUpdate) {
            ${activeGroupFields}
        }
    }
`;
