// Declared in public/config.(env).js to avoid having to modify these scripts at
// deploy time, which messes with the source maps
const globalConfig: { [key: string]: string | undefined } = (window as any).meanduConfig;

export const config: AppConfiguration = {
    ...globalConfig,
} as any;

export interface AppConfiguration {
    VERSION: string;
    VITE_VENUE_APP_LINK: string;
    VITE_SUPPORTAL_LINK: string;
    VITE_MANAGEMENT_API: string;
    VITE_OPS_API: string;
    VITE_MENU_DATA_URI: string;
    VITE_AUTH0_CLIENT_ID: string;
    VITE_AUTH0_DOMAIN: string;
    VITE_AUTH0_CALLBACK_URL: string;
    VITE_ORDER_APP_URL: string;
    VITE_LEGACY_REGION: string;
    VITE_ENABLE_LISTING_IMAGES: string;
    VITE_DISABLE_PERMISSIONS?: string;
    VITE_ENABLE_UPSELL_TYPES: string;
    VITE_ENABLE_MEMBERSHIP: string;
    VITE_ENABLE_MENU_BACKGROUND_IMAGE: string;
    VITE_ENABLE_FLEX_TABS: string;
    VITE_ENABLE_ANALYTICS_LAYOUT_CONFIG: string;
    VITE_SHOW_CREATE_DEFAULT_TABLE: string;
    VITE_HOTJAR_SITE_ID: string;
    VITE_ENABLE_DIAGNOSTICS: string;
    VITE_CHROME_EXTENSION_ID: string;
    VITE_SECTION_CREATE_DEFAULT_TABLE: string;
    VITE_ZENDESK_KEY: string;
}
