import { createAction } from "../reducers/edit";
import { createAction as activeGroupActions } from "../reducers/active";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { GroupSettingsUpdateInput, saveGroup } from "../api/saveGroup";
import { EditableGroup } from "../types/EditableGroup";
import { fetchActive } from "./fetchActive";
import { getSaveGroupTrackingEventData } from "../selectors/getSaveGroupTrackingEventData";

export const save = (groupId: string, editableGroup: EditableGroup) =>
    scaffoldSaveAction(
        undefined,
        createAction,
        async (_, dispatch) => {
            const groupSettingsUpdate = mapUpdateModel(editableGroup);
            const updatedGroup = await saveGroup(groupId, groupSettingsUpdate);

            await dispatch(activeGroupActions.success(updatedGroup));

            return updatedGroup.id;
        },
        undefined,
        false,
        (id: string) => fetchActive(id),
        undefined,
        (appState) => getSaveGroupTrackingEventData(appState, editableGroup)
    );

const mapUpdateModel = ({ engage: cep, displayName, privacyPolicyUrl }: EditableGroup): GroupSettingsUpdateInput => ({
    displayName,
    privacyPolicyUrl,
    integrationsSettings: {
        cepSettings: [
            {
                active: !!cep?.active,
                id: cep?.id,
                locationsEnabled: !cep?.active
                    ? undefined
                    : cep?.locationsEnabled?.map((l) => ({ locationId: l.id, isActive: l.isActive })),
            },
        ],
    },
});
