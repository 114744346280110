import * as Yup from "yup";
import { FileSchemaType } from "common/upload";
import { uploadedBrandingImageSchema } from "features/catalogue/schema";

export interface Branding {
    useCustomColor?: boolean;
    heroImage?: string | File | null;
    backgroundImage?: string | File | null;
    menuBackgroundImage?: string | File | null;
    logoUrl?: string | File | null;
    defaultCatalogueImage?: string | File | null;
    themeColor?: string;
}

export interface BrandDetails {
    heroImage?: string | null;
    backgroundImage?: string | null;
    menuBackgroundImage?: string | null;
    logoUrl?: string | null;
    defaultCatalogueImage?: string | null;
    themeColor?: string;
    useCustomColor?: boolean;
}

export const editableBrandingSchema = Yup.object<BrandDetails>().shape({
    heroImage: uploadedBrandingImageSchema,
    backgroundImage: uploadedBrandingImageSchema,
    menuBackgroundImage: Yup.lazy((value) =>
        typeof value === "string"
            ? Yup.string().required("Please select an image")
            : new FileSchemaType()
                  .nullable(true)
                  .type(/image\/(svg|png)/i, "Please select a valid SVG or PNG")
                  .fileSize(1024 * 1024, "Please select an image smaller than 1MB")
    ),
    defaultCatalogueImage: uploadedBrandingImageSchema,
    logoUrl: Yup.lazy((value) =>
        typeof value === "string"
            ? Yup.string().required("Please select an image")
            : new FileSchemaType()
                  .type(/image\/(svg)/i, "Please select a valid svg")
                  .fileSize(1024 * 1024, "Please select an image smaller than 1MB")
                  .svgComplexity(15000, "Select a less complex SVG to avoid performance issues on user devices")
                  .nullable(true)
    ),
    themeColor: Yup.string()
        .matches(/^#[A-Fa-f0-9]{6}$/, "Pleased provide a valid 6 digit hex that starts with hash sign")
        .required("Please select color or don't choose custom")
        .nullable(true),
});
